import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: initialState,
    reducers: {
        show(state, action) {
            state.isVisible = true;
        },
        hide(state, action) {
            state.isVisible = false;
        },
        toggle(state, action) {
            state.isVisible = !state.isVisible;
        },
    },
});

export const sidebarActions = sidebarSlice.actions;

export default sidebarSlice;
