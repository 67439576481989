import AuthRouter from 'modules/auth/AuthRouter';
import ProfileRouter from 'modules/profile/ProfileRouter';
import MainLayout from 'modules/shared/layouts/MainLayout/MainLayout';
import UnitManagementRouter from 'modules/unit-management/UnitManagementRouter';
import UserManagementRouter from 'modules/user-management/UserManagementRouter';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import lazyWithPreload from './config/lazy-with-preloading';
import BillingRouter from './modules/billing/BillingRouter';
import PCIntegrationRouter from './modules/pc-integration/PCIntegrationRouter';
import FallbackLoader from './modules/shared/components/FallbackLoader/FallbackLoader';
import AuthorizeToken from 'modules/auth/pages/AuthorizeToken/AuthorizeToken';

const PageNotFound = lazyWithPreload(() => import('modules/shared/pages/PageNotFound/PageNotFound'));

function AppRouter() {
    useEffect(() => {
        PageNotFound.preload();
    }, []);

    return (
        <Suspense fallback={<FallbackLoader />}>
            <Routes>
                <Route path="/login/*" element={<AuthRouter />} />
                <Route path="/authorize" element={<AuthorizeToken />} />
                <Route path="/*" element={<MainLayout />}>
                    <Route path="*" element={<UnitManagementRouter />} />
                    <Route path="users/*" element={<UserManagementRouter />} />
                    <Route path="profile/*" element={<ProfileRouter />} />
                    <Route path="billing/*" element={<BillingRouter />} />
                    <Route path="progresscenter/*" element={<PCIntegrationRouter />} />
                </Route>
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </Suspense>
    );
}

export default AppRouter;
