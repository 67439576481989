import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AuthGuard({ children, hasPermissions = [] }) {
    const location = useLocation();

    const { token, user } = useSelector((state) => state.auth);

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    for (let i = 0; i < hasPermissions.length; i++) {
        if (!user.permissions[hasPermissions[i]]) {
            return <Navigate to="/404" replace />;
        }
    }

    return children;
}

export default AuthGuard;
