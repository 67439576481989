/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'modules/shared/components/Modal/Modal';
import useConfirmationDialog from 'modules/shared/hooks/use-confirmation-dialog';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { authActions } from 'store/auth';
import { sidebarActions } from 'store/sidebar';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
    const queryClient = useQueryClient();

    const { isVisible } = useSelector((state) => state.sidebar);
    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const dialog = useConfirmationDialog();

    const { pathname } = useLocation();

    const hideSidebar = useCallback(() => {
        dispatch(sidebarActions.hide());
    }, [dispatch]);

    const handleLogout = useCallback(() => {
        queryClient.invalidateQueries();
        dispatch(authActions.logout());
    }, [dispatch, queryClient]);

    useEffect(() => {
        hideSidebar();
    }, [hideSidebar, pathname]);

    return (
        <>
            <div className={[styles.sidebar, isVisible ? styles.show : ''].join(' ')}>
                <div className={styles.sidebarBrand}>
                    <img src="/images/logo.svg" alt="" />
                </div>

                <button className={styles.sidebarClose} onClick={hideSidebar}>
                    <i className="fa fa-close"></i>
                </button>

                <div className={styles.sidebarNav}>
                    <div className="mt-3">
                        <h6 className={styles.navListHeading}>Manage</h6>

                        <ul className={styles.navList}>
                            <li>
                                <NavLink to="/" className={styles.sidebarLink}>
                                    My Quantams
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/users" className={styles.sidebarLink}>
                                    Users
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/fleet-list" className={styles.sidebarLink}>
                                    Fleet List
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/health-status" className={styles.sidebarLink}>
                                    Health Status
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/progresscenter" className={styles.sidebarLink}>
                                    ProgressCenter
                                </NavLink>
                            </li> */}
                            <li>
                                {user.permissions.account ? (
                                    <NavLink to="/billing" className={styles.sidebarLink}>
                                        Billing
                                    </NavLink>
                                ) : (
                                    <a
                                        className={styles.sidebarLink}
                                        onClick={() => dialog.show('You do not have access to billing')}
                                    >
                                        Billing
                                    </a>
                                )}
                            </li>
                        </ul>
                    </div>

                    <div className="mt-auto">
                        <h6 className={styles.navListHeading}>Account</h6>

                        <ul className={styles.navList}>
                            <li>
                                <NavLink to="/profile" className={styles.sidebarLink}>
                                    Profile
                                </NavLink>
                            </li>
                            <li>
                                <a className={styles.sidebarLink} onClick={handleLogout}>
                                    Sign out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Modal show={dialog.isOpen} onClose={() => dialog.hide()}>
                <div className="text-center p-3">{dialog.data}</div>
            </Modal>
        </>
    );
};

export default Sidebar;
