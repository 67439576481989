import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    amount: 0,
    currency: 'USD',
};

const billingSlice = createSlice({
    name: 'billing',
    initialState: initialState,
    reducers: {
        setAmount(state, action) {
            state.amount = action.payload;
        },
        setCurrency(state, action) {
            state.currency = action.payload;
        },
        resetAmount(state, action) {
            return initialState;
        },
    },
});

export const billingActions = billingSlice.actions;

export default billingSlice;
