import NProgress from 'nprogress';
import { useIsFetching } from 'react-query';

import styles from './ProgressLoader.module.scss';

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
});

function ProgressLoader() {
    const isFetching = useIsFetching();

    if (isFetching) {
        NProgress.start();
    } else {
        NProgress.done();
    }

    return null;
}

export default ProgressLoader;
