import React from 'react';
import styles from './ProgressSpinner.module.scss';

const getBorderWidth = (size) => {
    if (size < 24) return 3;
    else return 4;
};

const ProgressSpinner = (props) => {
    const { size, color } = props;

    const divStyle = () => {
        return {
            borderWidth: getBorderWidth(size),
            borderColor: `${color} transparent transparent transparent`,
        };
    };

    return (
        <div className={styles.loader} style={{ width: size + 'px', height: size + 'px' }}>
            <div style={divStyle()}></div>
            <div style={divStyle()}></div>
            <div style={divStyle()}></div>
            <div style={divStyle()}></div>
        </div>
    );
};

ProgressSpinner.defaultProps = {
    size: 42,
    color: 'var(--primary)',
};

export default ProgressSpinner;
