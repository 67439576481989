import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRouter from './AppRouter';

import ProgressLoader from 'modules/shared/components/ProgressLoader/ProgressLoader';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '../src/store';
import ErrorBoundary from './modules/shared/components/errors/ErrorBoundary';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        },
    },
});

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, [pathname]);
}

function App() {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <ProgressLoader />
                        <ScrollToTop />
                        <AppRouter />
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    </QueryClientProvider>
                </PersistGate>
                <ToastContainer autoClose={3000} hideProgressBar={true} />
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
