import lazyWithPreload from 'config/lazy-with-preloading';
import AuthGuard from 'modules/shared/components/AuthGuard/AuthGuard';
import { useEffect } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

const Profile = lazyWithPreload(() => import('./pages/Profile/Profile'));

const ProfileRouter = () => {
    useEffect(() => {
        Profile.preload();
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <AuthGuard>
                    <Profile />
                </AuthGuard>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to="/404" />,
        },
    ]);

    return routes;
};

export default ProfileRouter;
