import React from 'react';
import ProgressSpinner from 'modules/shared/components/ProgressSpinner/ProgressSpinner';
import styles from './PageLoader.module.scss';

const PageLoader = () => {
    return (
        <div className={styles.pageLoader}>
            <div className={styles.topbar}></div>

            <ProgressSpinner />
        </div>
    );
};

export default PageLoader;
