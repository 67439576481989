import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

const modalAnimation = {
    hidden: {
        opacity: 0,
        y: '-100vh',
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.1, type: 'spring', damping: 25, stiffness: 250 },
    },
    exit: {
        y: '-100vh',
        opacity: 0,
    },
};

const Modal = (props) => {
    const { show, onBackdropClick, onClose, modalClass } = props;

    // useEffect(() => {
    //     if (show) {
    //         document.body.classList.add('app-modal-backdrop');
    //     } else {
    //         document.body.classList.remove('app-modal-backdrop');
    //     }
    // }, [show]);

    return createPortal(
        <AnimatePresence initial={false} exitBeforeEnter={true}>
            {show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.backdrop}
                    onClick={onBackdropClick || onClose}
                >
                    <div className={styles.modelWrapper}>
                        <motion.div
                            variants={modalAnimation}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className={[styles.modal, modalClass].join(' ')}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button type="button" className={styles.modalClose} onClick={onClose} aria-label="Close">
                                <i className="fa fa-close"></i>
                            </button>

                            <div className={styles.modalBody}>{props.children}</div>
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById('modal-root')
    );
};

export default Modal;
