import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    user: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login(state, action) {
            const { token, user } = action.payload;

            state.token = token;
            state.user = user;
        },
        setToken(state, action) {
            state.token = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        removeToken(state, action) {
            state.token = null;
        },
        removeUser(state, action) {
            state.user = null;
        },
        logout() {
            return initialState;
        },
        // addItem(state, action) {
        //     const item = action.payload;

        //     state.items[item.id] = item;
        // },
        // removeItem(state, action) {
        //     const { id } = action.payload;

        //     delete state.items[id];
        // },
        // incrementItemQuantity(state, action) {
        //     const { id, quantity = 1 } = action.payload;

        //     const item = state.items[id];

        //     item.quantity += quantity;
        // },
        // decrementItemQuantity(state, action) {
        //     const { id, quantity = 1 } = action.payload;

        //     const item = state.items[id];

        //     item.quantity = Math.max(item.quantity - quantity, 0);

        //     // Remove item if quantity becomes zero
        //     if (item.quantity == 0) {
        //         delete state.items[id];
        //     }
        // },
        // setCharges(state, action) {
        //     state.charges = action.payload;
        // },
        // resetItems(state) {
        //     state.items = {};
        // },
        // setRestaurant(state, action) {
        //     state.restaurant = action.payload;
        // },
        // setCurrentPageRestaurant(state, action) {
        //     state.currentPageRestaurant = action.payload;
        // },
    },
});

export const authActions = authSlice.actions;

export default authSlice;
