import React from 'react';
import styles from './ErrorUI.module.scss';

const ErrorUI = (props) => {
    const goBack = () => {
        window.location.href = '/';
    };

    return (
        <div className={styles.errorUi}>
            <h1 className={styles.heading}>{props.heading}</h1>
            <div className={styles.message}>{props.message}</div>
            <button className="btn btn-primary mt-4" onClick={goBack}>
                Go Back Home
            </button>
        </div>
    );
};

export default ErrorUI;
