import { Component } from 'react';
import ErrorUI from '../ErrorUI/ErrorUI';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'development') {
            console.log({ error: error, errorInfo: errorInfo });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorUI
                    heading="Oops, something went wrong"
                    message={
                        <>
                            An Error occurred while processing your request. Please try again.
                            <br />
                            If this continues, please email us at{' '}
                            <a className="text-blue" href="mailto:hello@quantam.io">
                                hello@quantam.io
                            </a>
                        </>
                    }
                />
            );
        }

        return this.props.children;
    }
}
