import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const resellerSlice = createSlice({
    name: 'reseller',
    initialState: initialState,
    reducers: {
        setReseller(state, action) {
            return action.payload;
        },
        reset(state, action) {
            return initialState;
        },
    },
});

export const resellerActions = resellerSlice.actions;

export default resellerSlice;
