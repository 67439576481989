import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './auth';
import sidebarSlice from './sidebar';
import billingSlice from './billing';
import resellerSlice from './reseller';

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    sidebar: sidebarSlice.reducer,
    billing: billingSlice.reducer,
    reseller: resellerSlice.reducer,
});

const persistConfig = {
    key: 'quantam-reseller',
    version: 1,
    storage,
    blacklist: ['sidebar', 'reseller'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
});

export const persistor = persistStore(store);

export default store;
