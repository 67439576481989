import lazyWithPreload from 'config/lazy-with-preloading';
import AuthGuard from 'modules/shared/components/AuthGuard/AuthGuard';
import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

const BaseLayout = lazyWithPreload(() => import('./layouts/BaseLayout/BaseLayout'));
const ClientDetailLayout = lazyWithPreload(() => import('./layouts/ClientDetailLayout/ClientDetailLayout'));
const Dashboard = lazyWithPreload(() => import('./pages/Dashboard/Dashboard'));
const Clients = lazyWithPreload(() => import('./pages/Clients/Clients'));
const AddClient = lazyWithPreload(() => import('./pages/AddClient/AddClient'));
const ClientDetail = lazyWithPreload(() => import('./pages/ClientDetail/ClientDetail'));
const EditClient = lazyWithPreload(() => import('./pages/EditClient/EditClient'));
const Users = lazyWithPreload(() => import('./pages/Users/Users'));
const AllCameras = lazyWithPreload(() => import('./pages/AllCameras/AllCameras'));
const AddProject = lazyWithPreload(() => import('./pages/AddProject/AddProject'));
const ProjectDetail = lazyWithPreload(() => import('./pages/ProjectDetail/ProjectDetail'));
const ProjectDetailLayout = lazyWithPreload(() => import('./layouts/ProjectDetailLayout/ProjectDetailLayout'));
const AddUser = lazyWithPreload(() => import('./pages/AddUser/AddUser'));
const UserDetail = lazyWithPreload(() => import('./pages/UserDetail/UserDetail'));
const AddCamera = lazyWithPreload(() => import('./pages/AddCamera/AddCamera'));
const CameraDetail = lazyWithPreload(() => import('./pages/CameraDetail/CameraDetail'));
const SupportRequests = lazyWithPreload(() => import('./pages/SupportRequests/SupportRequests'));
const SupportRequestDetail = lazyWithPreload(() => import('./pages/SupportRequestDetail/SupportRequestDetail'));
const ResellerAccountSettings = lazyWithPreload(() =>
    import('./pages/ResellerAccountSettings/ResellerAccountSettings')
);

const ProgressCenterIntegrationRouter = () => {
    useEffect(() => {
        BaseLayout.preload();
        Dashboard.preload();
        Clients.preload();
        AddClient.preload();
        ClientDetail.preload();
        EditClient.preload();
        Users.preload();
        AllCameras.preload();
        AddProject.preload();
        ProjectDetail.preload();
        ProjectDetailLayout.preload();
        AddUser.preload();
        UserDetail.preload();
        AddCamera.preload();
        CameraDetail.preload();
        SupportRequests.preload();
        SupportRequestDetail.preload();
        ResellerAccountSettings.preload();
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: <BaseLayout />,
            children: [
                {
                    path: '',
                    element: (
                        <AuthGuard>
                            <Dashboard />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'clients',
                    element: (
                        <AuthGuard>
                            <Clients />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'clients/add',
                    element: (
                        <AuthGuard>
                            <AddClient />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'clients/:clientId',
                    element: <ClientDetailLayout />,
                    children: [
                        {
                            path: 'detail',
                            element: (
                                <AuthGuard>
                                    <ClientDetail />
                                </AuthGuard>
                            ),
                        },
                        {
                            path: 'users/add',
                            element: (
                                <AuthGuard>
                                    <AddUser />
                                </AuthGuard>
                            ),
                        },
                        {
                            path: 'users/:userId/detail',
                            element: (
                                <AuthGuard>
                                    <UserDetail />
                                </AuthGuard>
                            ),
                        },
                        {
                            path: 'projects/add',
                            element: (
                                <AuthGuard>
                                    <AddProject />
                                </AuthGuard>
                            ),
                        },
                        {
                            path: 'projects/:projectId',
                            element: <ProjectDetailLayout />,
                            children: [
                                {
                                    path: 'detail',
                                    element: (
                                        <AuthGuard>
                                            <ProjectDetail />
                                        </AuthGuard>
                                    ),
                                },
                                {
                                    path: 'cameras/add',
                                    element: (
                                        <AuthGuard>
                                            <AddCamera />
                                        </AuthGuard>
                                    ),
                                },
                                {
                                    path: 'cameras/:cameraId/detail',
                                    element: (
                                        <AuthGuard>
                                            <CameraDetail />
                                        </AuthGuard>
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'cameras',
                    element: (
                        <AuthGuard>
                            <AllCameras />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'support-requests',
                    element: (
                        <AuthGuard>
                            <SupportRequests />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'support-requests/:ticketId/detail',
                    element: (
                        <AuthGuard>
                            <SupportRequestDetail />
                        </AuthGuard>
                    ),
                },
                {
                    path: 'account/settings',
                    element: (
                        <AuthGuard>
                            <ResellerAccountSettings />
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: '*',
            element: <Navigate replace to="/404" />,
        },
    ]);

    return routes;
};

export default ProgressCenterIntegrationRouter;
