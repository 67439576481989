import lazyWithPreload from 'config/lazy-with-preloading';
import AuthGuard from 'modules/shared/components/AuthGuard/AuthGuard';
import { useEffect } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

const Users = lazyWithPreload(() => import('./pages/Users/Users'));
const AddUser = lazyWithPreload(() => import('./pages/AddUser/AddUser'));
const EditUser = lazyWithPreload(() => import('./pages/EditUser/EditUser'));

const UserManagementRouter = () => {
    useEffect(() => {
        Users.preload();
        AddUser.preload();
        EditUser.preload();
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <AuthGuard>
                    <Users />
                </AuthGuard>
            ),
        },
        {
            path: '/add',
            element: (
                <AuthGuard hasPermissions={['user_management']}>
                    <AddUser />
                </AuthGuard>
            ),
        },
        {
            path: '/:userId/edit',
            element: (
                <AuthGuard hasPermissions={['user_management']}>
                    <EditUser />
                </AuthGuard>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to="/404" />,
        },
    ]);

    return routes;
};

export default UserManagementRouter;
