import { useState } from 'react';

const useConfirmationDialog = () => {
    const [dialogData, setDialogData] = useState({ isOpen: false, data: null });

    const show = (data = null) => {
        setDialogData({ isOpen: true, data });
    };

    const hide = () => {
        setDialogData({ isOpen: false, data: null });
    };

    return {
        show,
        hide,
        isOpen: dialogData.isOpen,
        data: dialogData.data,
    };
};

export default useConfirmationDialog;
