import React from 'react';
import styles from './FallbackLoader.module.scss';

const FallbackLoader = () => {
    return (
        <div className={styles.fallbackLoader}>
            <img className={styles.loader} src="/images/loader.gif" alt="" />
        </div>
    );
};

export default FallbackLoader;
