import lazyWithPreload from 'config/lazy-with-preloading';
import AuthGuard from 'modules/shared/components/AuthGuard/AuthGuard';
import { useEffect } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

const Dashboard = lazyWithPreload(() => import('./pages/Dashboard/Dashboard'));
const UnitDetail = lazyWithPreload(() => import('./pages/UnitDetail/UnitDetail'));
const DropboxAuthorizationRedirect = lazyWithPreload(() =>
    import('./pages/DropboxAuthorizationRedirect/DropboxAuthorizationRedirect')
);
const FleetList = lazyWithPreload(() => import('./pages/FleetList/FleetList'));
const HealthStatus = lazyWithPreload(() => import('./pages/HealthStatus/HealthStatus'));

const UnitManagementRouter = () => {
    useEffect(() => {
        Dashboard.preload();
        UnitDetail.preload();
        FleetList.preload();
        HealthStatus.preload();
        DropboxAuthorizationRedirect.preload();
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <AuthGuard>
                    <Dashboard />
                </AuthGuard>
            ),
        },
        {
            path: '/units/:unitId',
            element: (
                <AuthGuard>
                    <UnitDetail />
                </AuthGuard>
            ),
        },
        {
            path: '/fleet-list',
            element: (
                <AuthGuard>
                    <FleetList />
                </AuthGuard>
            ),
        },
        {
            path: '/health-status',
            element: (
                <AuthGuard>
                    <HealthStatus />
                </AuthGuard>
            ),
        },
        {
            path: '/dropbox-authorization',
            element: (
                <AuthGuard>
                    <DropboxAuthorizationRedirect />
                </AuthGuard>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to="/404" />,
        },
    ]);

    return routes;
};

export default UnitManagementRouter;
